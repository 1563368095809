// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-kaleidoscope-tsx": () => import("./../../../src/pages/kaleidoscope.tsx" /* webpackChunkName: "component---src-pages-kaleidoscope-tsx" */),
  "component---src-pages-poem-tsx": () => import("./../../../src/pages/poem.tsx" /* webpackChunkName: "component---src-pages-poem-tsx" */),
  "component---src-pages-poi-tsx": () => import("./../../../src/pages/poi.tsx" /* webpackChunkName: "component---src-pages-poi-tsx" */)
}

